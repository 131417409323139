<template>
  <div class="geplante-bewegungen mb-12 border-b-2 border-gray-300 p-6">
    <section class="gp-bewegungen">
      <div
        class="
          main-action
          p-2
          gap-2
          text-sm text-secondary
          rounded
          mt-5
          md:flex
          justify-between
        "
      >
        <div class="add">
          <button
            type="button"
            @click="openModal(null)"
            title="neue Bewegungen erstellen"
            class="
              shadow-lg
              flex
              items-center
              text-lg
              border border-secondary
              py-1
              px-2
              rounded-lg
              font-light
              hover:text-white hover:bg-secondary hover:border-primary
            "
          >
            <span>{{ $t("new person") }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div class="gp-bewegungen-top-row">
        <div
          class="
            flex
            text-xs
            justify-between
            text-primary
            mb-4
            mt-4
            rounded
            border-2 border-secondary
            px-5
            py-2
          "
        >
          <div class="gp-bew-pers hidden md:block">
            <span>{{ $t("name") }}</span>
          </div>

          <div class="gp-bew-show">
            <span>{{ $t("action") }}</span>
          </div>
        </div>
      </div>

      <ul
        role="listbox"
        aria-labelledby="gp-bewegungen-label"
        id="gp-bewegungen"
        v-if="people.length"
      >
        <li
          class="bewegungen-hit items-center"
          role="option"
          aria-selected="false"
          v-for="person in people"
          :key="person.id"
        >
          <div class="bewegungen-inner items-center">
            <div class="gp-bew-cat">
              <span> {{ person.name }} </span>
            </div>

            <div class="ef-bew-action flex">
              <div class="edit">
                <button @click="openModal(person)">
                  <PencilAltIcon class="h-6 w-6 text-fuchsia-600" />
                </button>
              </div>
              <div class="delete">
                <button @click="deletePerson(person.id)">
                  <TrashIcon class="h-6 w-6 text-red-400" />
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </section>
  </div>
  <modal
    :open="showModal"
    @close="showModal = false"
    :type="type"
    :data="person"
  ></modal>
</template>

<script>
import { PencilAltIcon, TrashIcon } from "@heroicons/vue/outline";
import Modal from "@/components/Modal";

export default {
  data() {
    return {
      showModal: false,
      type: null,
      person: null,
    };
  },
  components: { PencilAltIcon, TrashIcon, Modal },
  computed: {
    people() {
      return this.$store.state.User.persons.data || [];
    },
    pagination() {
      return this.$store.state.User.persons.meta || {};
    },
  },
  methods: {
    openModal(person) {
      this.showModal = true;
      this.person = person;
      this.type = "PersonModal";
    },
    deletePerson(id) {
      this.$store.dispatch("User/DeletePersons", id).then((res) => {
        this.emitter.emit("showNotif", {
          type: res.type,
          msg: res.msg,
          showNotif: true,
        });
      });
    },
  },
  mounted() {
    this.$store.dispatch("User/getPersons");
  },
};
</script>
